const { baseAPI } = require('../config/config.json');
const axios = require('axios');
const storageService = require('./storage_service');
const HEADERS = {
    'Content-type': 'application/json',
    'Authorization': `Bearer ${storageService.getToken()}`
};

const login = (username, password) => {
    let body = {
        identifier: username,
        password
    }
    return axios.post(`${baseAPI}/api/auth/local`, body, HEADERS)
}

const forgotPassword = (email) => {
    let body = {
        email
    }
    return axios.post(`${baseAPI}/api/auth/forgot-password`, body, { headers: { "content-type": "application/json" } })
}

const changePassword = (body) => {
    return axios.post(`${baseAPI}/api/auth/change-password`, body, { headers: HEADERS })
}

const updateUser = (user) => {
    return axios.put(`${baseAPI}/api/users/${user.id}`, user, { headers: HEADERS })
}

const resetPassword = ({ password, passwordConfirmation, code }) => {
    let body = {
        passwordConfirmation,
        password,
        code
    }
    return axios.post(`${baseAPI}/api/auth/reset-password`, body, { headers: { "content-type": "application/json" } })
}

const logout = () => {
    storageService.clearAuth();
}

const verifyConnected = () => {
    return axios.get(`${baseAPI}/status`, {
        headers: {
            'Authorization': `Bearer ${storageService.getToken()}`
        }
    })
}

module.exports = {
    login,
    verifyConnected,
    logout,
    resetPassword,
    forgotPassword,
    changePassword,
    updateUser
}